<template>
    <div>
        <v-data-table :headers="headers" :items="tbData" :single-expand="singleExpand" :search="search"
            :loading="loadingTb" loading-text="Sedang Memuat Data..." :expanded.sync="expanded" item-key="id">

             <template v-slot:item.noRow="{ item }" >
                 {{tbData.map(function(x) {return x.omspanSp2dUploadId; }).indexOf(item.omspanSp2dUploadId)+1}}
             </template>

             <template v-slot:item.crtdt="{item}">
                <div class="p-2">
                    {{G_formatDate(item.crtdt,'d-M-Y H:i:s')}}
                </div>
            </template>
              <template v-slot:item.startDate="{item}">
                <div class="p-2">
                    {{G_formatDate(item.startDate,'d-M-Y')}}
                </div>
            </template>
            <template v-slot:item.endDate="{item}">
                <div class="p-2">
                    {{G_formatDate(item.endDate,'d-M-Y')}}
                </div>
            </template>

        </v-data-table>

      

    </div>
</template>
<script>
    export default {
        data() {
            return {
                expanded: [],
                singleExpand: false,
                  headers: [
                    { text: 'No', value: 'noRow',sortable: false,width: "1%" },
                    { text: 'ID', value: 'omspanSp2dUploadId' },
                    { text: 'Filename', value: 'filename' },
                    { text: 'Tanggal Upload', value: 'crtdt' },
                    { text: 'Start Date', value: 'startDate' },
                    { text: 'End Date', value: 'endDate' },
                ],
            }
        },
        mounted(){
           
        },
        methods: {
        
            getTable(){
                this.$emit('getTable');
            },
             edit(id){
                this.$emit('edit',id);
            },
             reset(id){
                this.$emit('reset',id);
            },
          
            
        },

        props: ['tbData', 'search', 'loadingTb'],
    }
</script>