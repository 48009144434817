import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: '',
      loadingTb: false,
      listCif: [],
      tbData: [],
      getData: [],
      setId: null,
      listEvent:[],
      uploadRule:[
        v => !!v || 'Tidak Boleh Kosong'
      ]
    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.getTable()

    this.G_GetAny('sakti/event').then(res => {
      let resData = res.data.content

      let listData = [];
      let arrSub = {};

      resData.forEach(v => {
          arrSub = {};

          arrSub['text'] = v.saktiEventNm;
          arrSub['value'] = v.saktiEventId;

          listData.push(arrSub);
      });

      this.listEvent = listData;

    })

   
  },
  components: {
    myTable
  },
  methods: {
    getTable() {
      this.loadingTb = true
      this.G_GetAny('upload/omspan/tabel-upload').then(res => {
        console.log(res);
        this.loadingTb = false
        this.tbData = res.data.content
      })
    },
    store() {
      let myForm = document.getElementById('formData');
      let formData = new FormData(myForm);
      
      if (this.$refs.form.validate()) {
        this.$refs.btnAct.load(true)
         
        this.G_PostAnyCustom('upload/omspan', formData).then(res => {
          // console.log(res.status);
          if (!res.status) {
            this.getTable();
            this.$snotify.success('Berhasil Upload Data');
     
          }else{
            this.$snotify.warning(res.data.message);
          }
        })
        .finally(() => {
          this.$refs.btnAct.load(false)
        });
      }
      else {
        this.$snotify.info('Lengkapi Data Terlebih Dahulu');
      }
    },
  }
}