var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tbData,"single-expand":_vm.singleExpand,"search":_vm.search,"loading":_vm.loadingTb,"loading-text":"Sedang Memuat Data...","expanded":_vm.expanded,"item-key":"id"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.noRow",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tbData.map(function(x) {return x.omspanSp2dUploadId; }).indexOf(item.omspanSp2dUploadId)+1)+" ")]}},{key:"item.crtdt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2"},[_vm._v(" "+_vm._s(_vm.G_formatDate(item.crtdt,'d-M-Y H:i:s'))+" ")])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2"},[_vm._v(" "+_vm._s(_vm.G_formatDate(item.startDate,'d-M-Y'))+" ")])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2"},[_vm._v(" "+_vm._s(_vm.G_formatDate(item.endDate,'d-M-Y'))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }